import { useCallback, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { MessagesActions, UIActions, UserSelectors } from "../../store";
import { ROUTES } from "../../constants/navigation.js";
import { getPageLink } from "../../utils/navigation";
import { emailToSpannerId } from "../../utils/string.js";

export const useChatActions = () => {
  const sendMessagePromise = useRef();
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const { getUser } = UserSelectors;
  const currentUser = useSelector(getUser);
  const [isTyping, setIsTyping] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { topicId: activeTopicId } = useParams();

  const clearNavigationState = useCallback(
    // clear state and prevent reload page
    () => window.history.replaceState({}, ""),
    []
  );

  const resetChat = useCallback(() => {
    dispatch(MessagesActions.resetMessages());
    dispatch(UIActions.resetChatWidgets());
  }, [dispatch]);

  const onSendMessage = useCallback(
    async (message, depots) => {
      dispatch(MessagesActions.savePendingMessage(message));
      setIsTyping(true);
      //save promise to abort it later if needed
      if (depots) {
        sendMessagePromise.current = dispatch(
          MessagesActions.sendMessageVolumeSimulator({
            question: message,
            depots,
            topicId: activeTopicId || "",
            userId: currentUser.userId,
            spannerUserId: emailToSpannerId(currentUser?.email) || "",
          })
        );
      } else {
        sendMessagePromise.current = dispatch(
          MessagesActions.sendMessageDashboard({
            question: message,
            topicId: activeTopicId || "",
            userId: currentUser.userId,
            spannerUserId: emailToSpannerId(currentUser?.email) || "",
          })
        );
      }
      const { payload } = await sendMessagePromise.current;
      console.log("payload", payload);
      setIsTyping(false);

      if (!payload) {
        return null;
      }

      dispatch(MessagesActions.removePendingMessage(payload.messages[0]));
      dispatch(UIActions.setChatWidget(payload));

      if (!activeTopicId && payload.attributes?.topicId) {
        const path = getPageLink(ROUTES.CHAT_TOPIC, {
          topicId: payload.attributes.topicId,
        });

        navigate(path, { state: { isSameChat: true }, replace: true });
      }

      return payload;
    },
    [activeTopicId]
  );

  const fetchTopicMessages = useCallback(async (activeTopicId) => {
    setIsLoading(true);

    const { error } = await dispatch(
      MessagesActions.fetchMessagesByTopicId(activeTopicId)
    );

    setIsLoading(false);

    if (error) {
      navigate(ROUTES.CHAT, { replace: true });
    }
  }, []);

  useEffect(() => {
    if (location.state?.isSameChat) {
      clearNavigationState();
      return;
    }

    resetChat();

    sendMessagePromise.current?.abort();

    activeTopicId && fetchTopicMessages(activeTopicId);
  }, [activeTopicId]);

  useEffect(() => {
    // show widget from the home page
    if (location.state?.widgetId) {
      dispatch(UIActions.showWidgetInChat(location.state?.widgetId));
      clearNavigationState();
    }

    // send a message from the home page
    if (location.state?.message) {
      onSendMessage(location.state.message);
      clearNavigationState();
    }

    return () => {
      sendMessagePromise.current?.abort();
      resetChat();
    };
  }, []);

  return { resetChat, onSendMessage, isTyping, isLoading, activeTopicId };
};

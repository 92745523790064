import React from "react";
import styled from "@emotion/styled";
import { Autocomplete, TextField, Button } from "@mui/material";

import { START_NEW_CHAT, AI_AGENTS } from "../../constants/footer";
import IconGrid from "./IconGrid.jsx";

const FooterContainer = styled.div(({ theme, maxWidth }) => ({
  display: "flex",
  backgroundColor: theme.colors.brandWhite,
  height: "8%",
  padding: "0 1rem",
  justifyContent: "space-between",
  alignItems: "center",
}));

const AiAgentDropDown = styled(Autocomplete)(({ theme }) => ({
  height: "60%",
  width: "15%",
  "& .MuiAutocomplete-inputRoot": {
    backgroundColor: theme.colors.brandWhite,
    padding: "0 0.5rem",
  },
  "& .MuiAutocomplete-input": {
    color: theme.colors.brandBlack,
  },
}));

const StyledButton = styled(Button)(({ theme }) => ({
  height: "55%",
  marginBottom: "0.35rem",
  color: theme.colors.brandBlack,
  backgroundColor: theme.colors.brandWhite,
  textTransform: "capitalize",
  border: `1px solid ${theme.colors.brandBlack38}`,
  fontSize: "0.8rem",
  padding: "0 0.5rem",
  "&:hover": {
    backgroundColor: theme.colors.brandRed,
  },
}));

export const Footer = ({ children, resetChat }) => {
  return (
    <FooterContainer>
      {children}
      <AiAgentDropDown
        disabled
        options={AI_AGENTS}
        defaultValue={"Gemini"}
        disableClearable
        renderInput={(params) => <TextField {...params} variant="outlined" />}
        onChange={(event, value) => {
          // TODO Do nothing for now
        }}
      />
      <StyledButton
        onClick={() => {
          resetChat();
        }}
      >
        {START_NEW_CHAT}
      </StyledButton>
      <IconGrid />
    </FooterContainer>
  );
};

import styled from "@emotion/styled";
import { Grid } from "@mui/material";

import MessageList from "./MessageList.jsx";
import aiLoader from "../../assets/ailoaderTransparent.gif";

const ChatPageContainer = styled("div")(({ theme, isChatActive }) => ({
  display: "flex",
  height: "100%",
  gap: "1rem",
  flex: 1,
  flexDirection: "row",
  wordBreak: "break-word",
  alignItems: "center",
  paddingTop: "0.1rem",
  [theme.breakpoints.down("md")]: {
    width: "200vw",
    paddingBottom: `${theme.dimensions.chat.switchButtonsHeight}px`,
    transition: "0.5s ease",
    transform: isChatActive ? "translateX(0)" : "translateX(-50%)",
    margin: `0 ${theme.spaces.xxLarge}px`,
  },
}));

// const ContentWrapper = styled("div")(({ theme }) => ({
//   display: "flex",
//   flex: 1,
//   flexDirection: "column",
//   wordBreak: "break-word",
//   alignItems: "center",
//   // margin: `0 ${theme.spaces.xLarge}px`,
//   [theme.breakpoints.down("md")]: {
//     margin: `0 ${theme.spaces.xxLarge}px`,
//   },
// }));

// const InputContainer = styled(Grid)(({ theme }) => ({
//   display: "flex",
//   transition: "all 0.5s ease-in-out",
//   // paddingBottom: theme.dimensions.chat.input.pBottom,
//   // paddingTop: theme.spaces.xxLarge,
//   // [theme.breakpoints.down("md")]: {
//   //   paddingTop: theme.spaces.large,
//   //   paddingBottom: theme.spaces.xxLarge,
//   // },
// }));

const ImageContainer = styled(Grid)(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  position: "absolute",
  top: 0,
  width: "100%",
  height: "100%",
}));

const Image = styled("img")({
  width: 120,
  height: 120,
});

// TODO - Chat could become a reusable component, only MessageForm will differ based on the product
const Chat = ({
  messages,
  user,
  onThumbDownClick,
  onThumbUpClick,
  isTyping,
  isLoading,
  isChatActive,
}) => (
  <ChatPageContainer isChatActive={isChatActive}>
    {isLoading && (
      <ImageContainer>
        <Image src={aiLoader} alt="Loading" />
      </ImageContainer>
    )}
    <MessageList
      picture={user.picture}
      messages={messages}
      onThumbDownClick={onThumbDownClick}
      onThumbUpClick={onThumbUpClick}
      isTyping={isTyping}
    />
  </ChatPageContainer>
);

export default Chat;

import { ThemeProvider } from "@mui/material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { SiteRoutes } from "./router/Routes";
import appTheme from "./theme";
import "dayjs/locale/en-gb";
import "./App.css";

function App() {
  return (
    <div className="App">
      <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="en-gb">
        <ThemeProvider theme={appTheme}>
          <SiteRoutes />
        </ThemeProvider>
      </LocalizationProvider>
    </div>
  );
}

export default App;

import React, { useState } from "react";
import styled from "@emotion/styled";

const IconContainer = styled.div(({ theme, maxWidth }) => ({
  display: "grid",
  gridTemplateColumns: "repeat(2, 50px)",
  justifyContent: "center",
  alignItems: "center",
  height: "100%",
  padding: "0.5rem 0",
}));

const Icon = styled.div(({ theme }) => ({
  width: "70%",
  height: "80%",
  borderRadius: "8px",
  cursor: "pointer",
  transition: "background 0.3s",
}));

// TODO make dynamic
const FullAIChatIcon = styled(Icon)(({ theme }) => ({
  background: theme.colors.gray10,
}));

const PageSplit8020Icon = styled(Icon)(({ theme }) => ({
  background: `linear-gradient(
    to right,
    ${theme.colors.gray10} 80%,
   ${theme.colors.gray70} 20%
  );`,
}));

const PageSplit5050Icon = styled(Icon)(({ theme }) => ({
  background: `linear-gradient(
    to right,
    ${theme.colors.infographicRed} 50%,
   ${theme.colors.brandRed} 50%
  );`,
}));

const FullPageContentIcon = styled(Icon)(({ theme }) => ({
  background: theme.colors.gray70,
}));

const IconGrid = () => {
  const [activeIcon, setActiveIcon] = useState(null);

  // TODO make dynamic
  return (
    <IconContainer>
      <FullAIChatIcon
        onClick={() => {
          setActiveIcon(0);
        }}
      />
      <PageSplit8020Icon
        onClick={() => {
          setActiveIcon(1);
        }}
      />
      <PageSplit5050Icon
        onClick={() => {
          setActiveIcon(2);
        }}
      />
      <FullPageContentIcon
        onClick={() => {
          setActiveIcon(3);
        }}
      />
    </IconContainer>
  );
};

export default IconGrid;

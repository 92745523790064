export const trimString = (string) => string && string.trim();

export const formatWidgetRowValue = (value, details) =>
  value
    ? `
  ${details?.prefix ? details.prefix : ""}${value}${
        details?.followingChar ? details.followingChar : ""
      }`
    : "—";

export const emailToSpannerId = (email) => {
  let user = email.split("@")[0];

  if (user.endsWith("1")) user = user.slice(0, -1);

  return user.toUpperCase();
};

import React, { useEffect, useMemo, useCallback, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "@emotion/styled";
import { AppActions } from "@dpdgroupuk/ai-app";

import { Header } from "./components/Header";
import { Sidebar } from "./components/Sidebar";
import { Mapper } from "./components/Mapper";
import { ErrorModal } from "./components/ErrorModal/index.js";
import Chat from "../../components/Chat/Chat.jsx";

import { getFormattedMessages } from "../../pages/models/messages";
import { MessagesSelectors, MessagesActions, UserSelectors } from "../../store";
import useModal from "../../pages/hooks/useModal";
import { useChatActions } from "../../pages/hooks/useChatActions";

import { getUserData } from "./utils/api.js";
import {
  setuserAccessDepotList,
  resetAppState,
  setShowErrorModal,
  setErrorModalMessage,
} from "./reducers/app";
import { showErrorModalSelector } from "./reducers/app/selector.js";

const Container = styled("div")(({ theme }) => ({
  display: "flex",
  justifyContent: "flex-end",
  gap: "1rem",
  height: "92%",
  // rowGap: theme.spaces.xxLarge,
}));

const Segment = styled("div")(() => ({
  height: "100%",
  width: "100%",
  overflow: "hidden",
}));

export const VolumeSimulatorPage = ({ spannerUserId }) => {
  const dispatch = useDispatch();

  const { setLoading } = AppActions;

  const showErrorModal = useSelector(showErrorModalSelector);
  const [isChatActive, setIsChatActive] = useState(true);
  const { isTyping, isLoading } = useChatActions();
  const user = useSelector(UserSelectors.getUser);
  const messages = useSelector(MessagesSelectors.getMessages);

  useEffect(() => {
    dispatch(resetAppState());
    const findUser = async () => {
      try {
        dispatch(setLoading(true));
        const user = await getUserData(spannerUserId);

        dispatch(setuserAccessDepotList(user.data.data.user.depotAccess));
        dispatch(setLoading(false));
      } catch (e) {
        dispatch(setShowErrorModal(true));
        dispatch(setErrorModalMessage("Getting User Depot Data"));
        dispatch(setLoading(false));
      }
    };

    findUser();
  }, [dispatch, setLoading, spannerUserId]);

  const preparedMessages = useMemo(
    () => getFormattedMessages(messages),
    [messages]
  );

  const onSubmitFeedback = useCallback(
    async (messageId, feedbackComment) => {
      const { payload } = dispatch();
      MessagesActions.dislikeMessage({
        messageId,
        feedbackComment,
      });

      if (payload) {
        dispatch(MessagesActions.dislikeLocalMessage(messageId));
      }
    },
    [dispatch]
  );

  const { onModalOpen } = useModal({
    onSubmit: onSubmitFeedback,
  });

  const onThumbUpClick = useCallback(
    async (messageId) => {
      const { payload } = await dispatch();
      MessagesActions.likeMessage(messageId);

      if (payload) {
        dispatch(MessagesActions.likeLocalMessage(messageId));
      }
    },
    [dispatch]
  );

  return (
    <>
      {showErrorModal && <ErrorModal />}
      <Container>
        <Segment>
          <Chat
            messages={preparedMessages}
            user={user}
            onThumbDownClick={onModalOpen}
            onThumbUpClick={onThumbUpClick}
            isTyping={isTyping}
            isLoading={isLoading}
            isChatActive={isChatActive}
          />
        </Segment>
        <Segment>
          <Header />
          <Sidebar />
          <Mapper />
        </Segment>
      </Container>
    </>
  );
};

import styled from "@emotion/styled";

const PageContainer = styled.div(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  height: "calc(100% - 64px)",
  width: "100%",
  backgroundColor: theme.colors.brandLightGrey,
  [theme.breakpoints.down("lg")]: {
    padding: `${theme.dimensions.home.paddingVertical}px ${
      theme.dimensions.home.paddingHorizontal / 2
    }px`,
  },
  [theme.breakpoints.between(930, 1040)]: {
    padding: `${theme.dimensions.home.paddingVertical}px ${theme.dimensions.home.paddingHorizontal}px`,
  },
  [theme.breakpoints.down("sm")]: {
    padding: `${theme.dimensions.home.paddingVertical}px 0px`,
  },
}));

export default PageContainer;

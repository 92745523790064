import { Grid } from "@mui/material";
import styled from "@emotion/styled";

import aiLoader from "../../assets/ailoaderTransparent.gif";

import Message from "./Message.jsx";

const MessagesContainer = styled(Grid)(({ theme }) => ({
  display: "inline-block",
  width: "100%",
  height: "100%",
  paddingTop: 2,
  overflowY: "scroll",
  flexDirection: "column-reverse",
  flexWrap: "nowrap",
  display: "flex",
  [theme.breakpoints.down("md")]: {
    height: `calc(100dvh - ${theme.dimensions.layout.headerHeight}px - ${theme.dimensions.chat.inputContainer.mobHeight}px - ${theme.dimensions.chat.switchButtonsHeight}px)`,
  },
}));

const Image = styled("img")({
  width: 70,
  height: 70,
  marginLeft: 10,
});

export const MessageList = ({
  messages = [],
  onThumbDownClick,
  onThumbUpClick,
  picture,
  isTyping,
}) => (
  <MessagesContainer>
    {isTyping && (
      <Grid container>
        <Image src={aiLoader} alt="typing" />
      </Grid>
    )}
    {messages.map(
      ({ message, isUser, isLoading, feedbackType, id, isSystem }, i) => (
        <Message
          picture={picture}
          key={id || i}
          message={message}
          isUser={isUser}
          isLoading={isLoading}
          thumbDownClick={() => onThumbDownClick(id)}
          thumbUpClick={() => onThumbUpClick(id)}
          feedbackType={feedbackType}
          id={id}
          isSystem={isSystem}
        />
      )
    )}
  </MessagesContainer>
);

export default MessageList;

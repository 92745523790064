import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { AppActions } from "@dpdgroupuk/ai-app";

import PageContainer from "../../components/grid/PageContainer.jsx";
import { UserSelectors } from "../../store";
import { VolumeSimulatorPage } from "../../products/volume-sim/index.js";
import { Footer } from "../../components/Footer/index.jsx";
import { useChatActions } from "../hooks/useChatActions.js";
import { userAccessDepotListSelector } from "../../products/volume-sim/reducers/app/selector.js";
import { trimString, emailToSpannerId } from "../../utils/string.js";
import {
  handleSimulation,
  handleVolumeSimulatorDepotChange,
  handleVolumeSimulatorDateChange,
} from "../../products/volume-sim/reducers/app/thunkActions.js";
import MessageForm from "../../components/Chat/MessageForm.jsx";

export const HomePage = () => {
  const { resetChat, onSendMessage, isTyping, activeTopicId } =
    useChatActions();
  const userDepotList = useSelector(userAccessDepotListSelector);

  const dispatch = useDispatch();

  const spannerUserId =
    emailToSpannerId(useSelector(UserSelectors.getUser)?.email) || "";

  const { setLoading } = AppActions;

  const sendMessageToVertex = async (message, setMessage) => {
    const filteredDepots = await userDepotList.map(
      ({ depotCode, depotName }) => ({
        depotCode,
        depotName,
      })
    );
    const res = await onSendMessage(trimString(message), filteredDepots);
    const {
      depotCodeList = [],
      depotNameList = [],
      date = null,
      percentage = 0,
      districtAndSectorList = [],
    } = await res.messages[0];
    setMessage("");
    // Stops requests going through if there's no depot code -> Exception for simulating certain sectors and districts without mentioning depotCode
    if (depotCodeList?.length < 1 && districtAndSectorList?.length < 1) return;
    dispatch(setLoading(true));
    try {
      await dispatch(
        handleVolumeSimulatorDepotChange({
          depotCodeList,
          depotNameList,
          districtAndSectorList,
        })
      );
      if (date) {
        await dispatch(
          handleVolumeSimulatorDateChange({
            date,
            depotCodeList,
            districtAndSectorList,
          })
        );
      }
      if (percentage) {
        await dispatch(
          handleSimulation({
            percentage: percentage / 100,
            depotCodeList,
            date,
            districtAndSectorList,
          })
        );
      }
      dispatch(setLoading(false));
    } catch (e) {
      // Each function already has its own error handling - this is here to stop them running if one of the functions before fails
      dispatch(setLoading(false));
    }
  };

  return (
    <PageContainer>
      <VolumeSimulatorPage spannerUserId={spannerUserId} />
      <Footer resetChat={resetChat}>
        <MessageForm
          sendMessageToVertex={sendMessageToVertex}
          isTyping={isTyping}
          activeTopicId={activeTopicId}
        />
      </Footer>
    </PageContainer>
  );
};

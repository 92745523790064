import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { TextField, Autocomplete } from "@mui/material";
import { AppActions } from "@dpdgroupuk/ai-app";

import {
  resetDepot,
  handleVolumeSimulatorDepotChange,
} from "../../reducers/app";
import {
  // fullMapOverlayDateSelector,
  // pageToShowIndexSelector,
  volumeOverlayDateSelector,
  userAccessDepotListSelector,
  depotNameListSelector,
} from "../../reducers/app/selector";
// import { firebaseUserSelector } from "../../reducers/user/selector";
import { SubHeader } from "./SubHeader";
import { VolumeOverlay } from "./VolumeOverlay";
import { DEFAULT_MAPBOUNDS } from "../../constants/map.js";
import "./Header.css";
import { setMapBounds } from "../../reducers/map/index.js";
import { setBoundariesForSimulationMap } from "../../reducers/depot/index.js";

export const Header = () => {
  const depotNameList = useSelector(depotNameListSelector);
  const selectedLocation =
    (depotNameList.length === 1 && depotNameList?.[0]) || "";

  const { setLoading } = AppActions;

  // const pageToShowIndex = useSelector(pageToShowIndexSelector);
  // const user = useSelector(firebaseUserSelector);
  const userAccessDepotList = useSelector(userAccessDepotListSelector);
  const volumeOverlayDate = useSelector(volumeOverlayDateSelector);
  // const fullMapOverlayDate = useSelector(fullMapOverlayDateSelector);

  const dispatch = useDispatch();

  const depotDropDownList =
    userAccessDepotList?.length > 1
      ? userAccessDepotList.map((depot) => {
          const depotName = depot.depotName || "";
          const depotCode = depot.depotCode || "";
          return { depotName, depotCode, label: `${depotName} (${depotCode})` };
        })
      : [{ depotName: "", depotCode: "", label: "No Options" }];

  return (
    <div className="header">
      {/* Manual use of Volume Sim only works with 1 depot - for now */}
      <Autocomplete
        className="headerDepotSearchBox"
        disablePortal
        blurOnSelect
        options={depotDropDownList}
        onChange={async (event, newValue) => {
          // Pressing the clear button will set newValue to null
          if (!newValue) {
            dispatch(resetDepot());
            dispatch(
              setBoundariesForSimulationMap({
                sectorBoundaries: [],
                districtBoundaries: [],
              })
            );
            dispatch(setMapBounds(DEFAULT_MAPBOUNDS));
            return;
          }
          const { depotName, depotCode } = newValue;
          dispatch(setLoading(true));
          // Ignore await message - await IS needed here otherwise loading screen will disappear instantly
          await dispatch(
            handleVolumeSimulatorDepotChange({
              depotNameList: [depotName],
              depotCodeList: [depotCode],
            })
          );
          dispatch(setLoading(false));
        }}
        renderInput={(params) => (
          <TextField {...params} label="Choose Depot" variant="outlined" />
        )}
      />

      <VolumeOverlay setLoading={setLoading} />

      <SubHeader />
    </div>
  );
};

import React from "react";
import { useNavigate } from "react-router-dom";
import styled from "@emotion/styled";
import { Button } from "@mui/material";

import { ReactComponent as RedStars } from "../../assets/redStars.svg";

const StyledButton = styled(Button)(({ theme }) => ({
  width: "fit-content",
  color: theme.colors.brandBlack,
  backgroundColor: theme.colors.brandWhite,
  textTransform: "capitalize",
  border: `1px solid ${theme.colors.brandBlack38}`,
  borderRadius: "1rem",
  fontSize: "0.7rem",
  fontWeight: "600",
  padding: "0.35rem 1rem 0.35rem 0.7rem",
}));

const StyledRedStars = styled(RedStars)({
  marginRight: "0.5rem",
});

export const NavigationButton = ({ title, link }) => {
  const navigate = useNavigate();

  return (
    <StyledButton onClick={() => navigate(link)}>
      <StyledRedStars />
      {title}
    </StyledButton>
  );
};

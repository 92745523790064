import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import dayjs from "dayjs";
import { TextField } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

import {
  handleVolumeSimulatorDateChange,
  resetPageData,
} from "../../../reducers/app";
import {
  activeDatesForAllocationDatePickerSelector,
  activeDatesForSimulationDatePickerSelector,
  fullMapOverlayDateSelector,
  pageToShowIndexSelector,
  volumeOverlayDateSelector,
  depotCodeListSelector,
} from "../../../reducers/app/selector";

export const VolumeOverlay = ({ setLoading }) => {
  const dispatch = useDispatch();
  const [openDatePicker, setOpenDatePicker] = useState(false);
  const volumeOverlayDate = useSelector(volumeOverlayDateSelector);
  const depotCodeList = useSelector(depotCodeListSelector);
  const pageToShowIndex = useSelector(pageToShowIndexSelector);
  const activeSimulationDates = useSelector(
    activeDatesForSimulationDatePickerSelector
  );
  const activeAllocationDates = useSelector(
    activeDatesForAllocationDatePickerSelector
  );
  const fullMapOverlayDate = useSelector(fullMapOverlayDateSelector);

  const activeDates =
    pageToShowIndex === 0 ? activeSimulationDates : activeAllocationDates;

  const maxDate = activeDates[0]
    ? new Date(activeDates[activeDates.length - 1])
    : new Date();

  const minDate = activeDates[0] ? new Date(activeDates[0]) : new Date();

  return (
    <DatePicker
      key={`date-picker-${depotCodeList?.[0]}`}
      label="Choose Date"
      className="headerOverlayDateInput"
      // value={dayjs()}
      onChange={async (newDate) => {
        if (!newDate) {
          dispatch(resetPageData());
          return;
        }
        dispatch(setLoading(true));
        await dispatch(
          handleVolumeSimulatorDateChange({
            date: dayjs(newDate).format("YYYY-MM-DD"),
            depotCodeList,
          })
        );
        dispatch(setLoading(false));
      }}
      open={openDatePicker}
      onOpen={() => setOpenDatePicker(true)}
      onClose={() => setOpenDatePicker(false)}
      maxDate={dayjs(maxDate)}
      minDate={dayjs(minDate)}
      disabled={depotCodeList?.length !== 1}
      shouldDisableDate={(date) => {
        return !activeSimulationDates.includes(
          dayjs(date).format("YYYY-MM-DD")
        );
      }}
      inputProps={{
        textAlign: "left",
      }}
      slotProps={{
        textField: {
          onClick: () => setOpenDatePicker(true),
        },
        field: { clearable: true },
      }}
      textField={(params) => (
        <TextField
          onKeyDown={(e) => {
            e.preventDefault();
          }}
          {...params}
        />
      )}
    />
  );
};
